.writer-selector {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px;
	position: absolute; /* fixed에서 absolute로 변경 */
	height: 100vh;
	top: 0;
	left: 0;
	right: 0; /* 추가 */
	bottom: 0; /* 추가 */
	background-color: rgba(245, 245, 245, 0.98);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}

.writers-list {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	overflow-y: auto;
}

.writer-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 0;
	background-color: #eaeaea; /* 연한 회색으로 변경 */
	border: 1px solid #bbb; /* 경계선 색 변경 */
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	transition:
		box-shadow 0.3s,
		transform 0.3s;
	border-radius: 5px;
}

.writer-item:hover {
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
	transform: translateY(-2px);
}

.writer-item span {
	flex-grow: 1;
	margin-right: 20px;
	font-size: 18px;
	padding: 15px;
}

.writer-item button {
	padding: 8px 16px;
	margin-right: 10px;
	background-color: #5c6bc0; /* 진한 라벤더색 */
	color: #fff;
	border-radius: 4px;
	font-weight: bold;
	transition:
		background-color 0.3s,
		transform 0.3s;

	&:hover {
		background-color: #3949ab; /* 더 진한 라벤더색 */
		transform: scale(1.05);
	}
}

.selected-writer {
	font-size: 20px;
	color: #333;
	padding: 12px;
	background-color: #9fa8da; /* 부드러운 라벤더색 */
	border-radius: 5px;
	width: 100%;
	text-align: center;
}

.addWriter {
	padding: 12px 24px;
	margin-top: 20px;
	background-color: #26a69a; /* 틸색 */
	color: white;
	font-size: 16px;
	border: none;
	border-radius: 6px;
	cursor: pointer;
	transition:
		background-color 0.3s,
		transform 0.3s;

	&:hover {
		background-color: #00796b; /* 더 진한 틸색 */
		transform: translateY(-3px);
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	}
}
