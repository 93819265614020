body {
	background-color: #fcfcfc;
}
input[type="checkbox"],
input[type="radio"] {
	accent-color: #343434; /* 최신 브라우저에서 지원 */
}

h4 {
	margin-top: 20px;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.postSubTitle {
	padding-bottom: 12px;
	border-bottom: 2px solid #efefef;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.postMain {
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;

	h2 {
		width: 90%;
		padding: 20px;
		text-align: left;
	}
}
.postall {
	width: 90%;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	position: relative;
	padding: 30px 0;
	margin-bottom: 50px;
}
@media print {
	.postall {
		size: A4;
		//transform: scale(0.8);
		//transform-origin: top left;
	}
	.chatBtn {
		display: none;
	}
	.rmflavks {
		width: 50%;
	}
}
.sizening {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 50px;
	width: 95%;
}
.filedockImg {
	width: 25px;
	height: 25px;
	margin: 0 10px 0 0;
}
.imgdockflex {
	align-items: center;
}
// 상단 Header
.postHeaderSet {
	width: 100%;
	justify-content: space-between;
	align-items: center;
	h1 {
		white-space: nowrap;
		padding: 25px 0;
	}
}
.postFooterSet {
	width: 100%;
	height: 55px;
	background-color: #d9d9d9;
	position: sticky;
	bottom: 0;
	button {
		background-color: #8d8d8d;
		color: white;
		font-size: 20px;
		border-radius: 2px;
		width: 100px;
		height: 45px;
		margin-top: 5px;
		margin-right: 5px;
		float: right;
	}
}
// 상단 인풋 정렬
.postInputSet {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: start;
	background-color: #fcfcfc;
	padding: 20px 0px 34px;
}
// 상단 인풋 사이즈
.postInputSize {
	display: flex;
	justify-content: space-between;
	white-space: nowrap;
	flex-basis: 240px;
	label {
		white-space: nowrap;
		margin: 10px 0px 10px 8px;
		padding: 5px 10px;
	}
	input,
	select {
		margin: 10px 0px;
		padding: 5px 5px;
		outline: none;
		width: 60%;
		border: 1px solid #d8d8d8;
	}
	input:focus {
		outline: 2px solid #343434;
	}
	input::placeholder {
		color: #ec0b0b; /* 밝은 빨간색 */
		opacity: 1;
	}
}
// 라디오 사이즈
.postRadioSet {
	display: flex;
	flex-wrap: wrap;
	width: 66vw;
}
// 라디오 사이즈
.postRadioSize {
	display: flex;
	justify-content: space-between;
	align-items: center;
	white-space: nowrap;
	flex-basis: 108px;
	label {
		white-space: nowrap;
		margin: 10px 80px 10px 8px;
		padding: 5px 10px;
	}
	.RadioInputSize {
		height: 30px;
		padding: 5px 5px;
		outline: none;
	}
	.etcInput {
		margin: 10px 20px 10px 8px;
		padding: 5px 10px;
	}
}

.size4-5 {
	width: 300px;
	.firstselctshade {
		margin-right: 80px;
	}
}
.dentalChart {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: Arial, sans-serif;
}

.statusButtons {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	width: 100%; /* Adjust as necessary */
}

.statusButtons button {
	flex-grow: 1;
	margin: 0 5px;
	padding: 10px;
	border: 1px solid #ccc;
	background-color: #fcfcfc;
	cursor: pointer;
}

.statusButtons button:hover {
	background-color: #e0e0e0;
}

.toothGrid {
	display: grid;
	grid-template-columns: repeat(
		16,
		1fr
	); /* Adjust the number of columns based on your layout */
	grid-gap: 5px;
	width: 100%; /* Adjust as necessary */
	margin-bottom: 10px;
}

.toothButton {
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 50%;
	background-color: #fff;
	cursor: pointer;
	transition: background-color 0.2s;
}

.toothButton:hover {
	background-color: #e0e0e0;
}

.selectedTooth {
	background-color: #ffaaaa;
	border-color: #ff7777;
}

/* You can add media queries to make the layout responsive */
@media (max-width: 768px) {
	.toothGrid {
		grid-template-columns: repeat(8, 1fr);
	}

	.statusButtons button {
		padding: 5px;
		font-size: 0.8rem;
	}
}

// 3D 스캔

.postPicBox {
	margin: 30px 0px 60px;
}
.shadeBox {
	margin: 30px 0px 60px;
}
.postPicBox p {
	margin-right: 10px; /* 제목과 체크박스 사이의 간격 */
}

.postPicBox input[type="text"] {
	margin-right: 10px; /* 입력 필드와 버튼 사이의 간격 */
	padding: 5px; /* 입력 필드 내부의 패딩 */
	border: 1px solid #ccc; /* 입력 필드의 테두리 */
	border-radius: 4px; /* 입력 필드의 테두리 둥글기 */
}

.postPicBox input[type="checkbox"] {
	margin-left: 10px; /* 체크박스를 오른쪽 끝으로 정렬 */
}

// 쉐이드 셀렉트
.shadeSelect div {
	width: 469px;
	display: flex;
	margin-bottom: 10px;
}
.shadeSelect label {
	width: 160px;
}

.shadeSelect select {
	width: 180px;
	height: 20px;
	color: #343434;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border: 1px solid #d2d2d2;
}
// 그리드
.gridsetBox {
	width: 66vw;
	display: grid;
	display: flex;
	justify-content: space-between;
}
.gridsetBoxWrapper {
	display: flex;
}
.clipBoardCanvasSection {
	width: 50%;
	position: relative;
	> div {
		position: absolute;
		margin-top: 60px;
	}
}
// 클립보드
.ClipBoardBox {
	width: 730px;
	.past {
		width: 100%;
		height: 520px;
		overflow-y: scroll;
		position: relative;
		border-radius: 10px;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
		background-color: white;
		.output {
			width: 100%;
			margin-top: -510px;
			text-align: center;
			> div {
				position: relative;
				img,
				video {
					max-height: 500px;
					width: 100%;
					object-fit: cover;
				}
				button {
					position: absolute;
					//padding: 10px 15px;
					width: 30px;
					height: 30px;
					z-index: 4;
					margin-left: -30px;
					background: rgba(0, 0, 0, 0.8);
					color: white;
				}
			}
		}
		input {
			width: 100%;
			height: 100%;
			background: none;
			position: sticky;
			top: 0;
			left: 0;
			z-index: 3;
			cursor: default;
			border: none;
			padding: 30px;
		}
	}
}
//그림판
.rmflavks {
	width: 730px;
	button {
		background: none;
	}
	> div {
		border-radius: 10px;
		overflow: hidden;
		width: 100%;
		height: 520px;
		position: relative;
		border: 1px solid #cbcbcb;
		background: #fff;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
	}
	.movesaveBtn {
		width: 100%;
		height: 50px;
		background-color: #e6f8e4;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		button {
			width: 32px;
			height: 32px;
			position: relative;
			img {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
	.backgroundBtn {
		position: absolute;
		left: 20px;
		top: calc(50% + (50px / 2));
		transform: translateY(-50%);
		display: flex;
		flex-direction: column;
		gap: 15px;
		button {
			width: 100px;
			height: 60px;
			background: #fff;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
			position: relative;
			&.colorBtn {
				&::after {
					content: "✓";
					width: 16px;
					height: 16px;
					background-color: #54e940;
					color: white;
					border-radius: 100%;
					position: absolute;
					left: -8px;
					top: -8px;
				}
			}
			img {
				height: 80%;
			}
		}
	}
	.imgFileBox {
		width: 100%;
		height: calc(100% - 50px);
		//border: 1px solid black;
	}
}

//클립보드,그림판 h4
h4.clipBoardCanvasTitle {
	margin-bottom: 25px;
	float: left;
}
//클립보드,그림판 버튼
button.clipBoardCanvasBtn {
	height: 35px;
	padding: 10px;
	margin-left: 10px;
	font-weight: 700;
	background: none;
	border-left: 2px solid #d9d9d9;
}
.chatBtn {
	position: fixed;
	top: 20%;
	right: 3%;
	padding: 15px 15px 10px;
	background-color: #4caf50; /* 녹색 배경 */
	color: white; /* 텍스트 색상 */
	border: none; /* 테두리 없음 */
	border-radius: 50%; /* 모서리 둥글게 */
	cursor: pointer; /* 마우스 오버시 커서 변경 */
	font-size: 16px; /* 글꼴 크기 */
	transition: all 0.3s ease; /* 호버 효과를 위한 전환 */

	/* 그림자 효과 */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

	/* 마우스 오버시 */
	&:hover {
		background-color: #45a049; /* 배경색 변경 */
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* 그림자 효과 강화 */
	}
}

.cltlr-CltlrAll {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 20px;
	width: 66vw;
}

.cltlr-flexCol,
.cltlr-flexRow {
	display: flex;
	&.cltlr-flexCol {
		flex-direction: column;
	}
	&.cltlr-flexRow {
		flex-direction: row;
	}
}
.talkIcon {
	width: 40px;
}
.cltlr-buttonGroup {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.cltlr-button {
	margin: 5px;
	padding: 10px;
	border: 2px solid #ccc;
	border-radius: 5px;
	background-color: transparent;
	user-select: none;
	cursor: pointer;
	transition:
		background-color 0.3s,
		border-color 0.3s;

	&:hover {
		border-color: #888;
	}

	&.cltlr-selected {
		background-color: #00bfff;
		color: white;
		border-color: #00a3e0;
	}
}

.cltlr-textGroup > div {
	margin: 8px;
	padding: 6px 12px; /* 패딩 크기 감소 */
	border: none;
	border-radius: 4px;
	background-color: #f9f9f9; /* 밝은 회색으로 변경 */
	color: #333;
	text-align: center;
	font-size: 12px; /* 글꼴 크기 감소 */
	cursor: pointer;
	transition:
		background-color 0.3s,
		color 0.3s;
}

.cltlr-textGroup > div:hover {
	background-color: #4a90e2; /* 밝은 파란색으로 변경 */
	color: white;
}

.cltlr-textGroup > div:active {
	background-color: #357abd; /* 진한 파란색으로 변경 */
}

.rlrhdanfBox {
	height: 111px;
}

.addedInputSelect {
	width: 100px;
	height: 24px;
}

.addedInputSelect option {
	color: #343434;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.selectA {
	margin-left: 5px;
}
.addedInputSelect:focus {
	border: none;
	outline: 2px solid #343434;
	border-radius: 3px;
}

.addedBox {
	margin: 0px 20px;
}

.AddedLabel {
	color: #343434;
	text-align: right;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	margin-right: 11px;
}

// 파일 업로드 버튼 디자인
.custom-file-upload {
	padding: 4px 14px;
	color: #fff;
	border-radius: 4px;
	background: #104168;
	cursor: pointer;
	font-size: 14px;
	margin-right: 12px;
}

.uploaddisabled {
	padding: 4px 14px;
	color: #fff;
	border-radius: 4px;
	cursor: pointer;
	font-size: 14px;
	margin-right: 12px;
	border-radius: 4px;
	background: #acacac;
}

.deleteSelected:disabled {
	color: #acacac;
	border-radius: 4px;
	border: 1px solid #acacac;
	background: #fff;
}

.fileUploadBox {
	justify-content: right;
	margin: 10px 0px;
}

// 파일 삭제 버튼 디자인
.deleteSelected {
	padding: 4px 14px;
	background-color: #007bff; /* 배경색 설정 */
	border-radius: 4px;
	border: 1px solid #104168;
	background: #fff;
	color: #104168;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.fileCaption {
	padding: 25px 90px;
}
textarea {
	border: 1px solid #e3e3e3;
	width: 100%;
	outline: none;
	margin-top: 30px;
	font-size: 20px;
	height: 40px;
	line-height: 38px;
	padding: 0 5px;
	color: #333;
}
// 파일 리스트 디자인
.fileListContainer {
	height: 71.5px;
	width: 100%;
	border-collapse: collapse; /* 테이블 테두리 겹치기 제거 */
	overflow-y: scroll;
	background-color: #fafafa; /* 배경색 */
}
/* 스크롤바 전체 스타일 */
.fileListContainer::-webkit-scrollbar {
	width: 3px; /* 스크롤바의 너비 */
	height: 5px; /* 수평 스크롤바의 높이, 수직 스크롤 시 필요 */
}

/* 스크롤바 트랙 (트랙은 스크롤바 배경) */
.fileListContainer::-webkit-scrollbar-track {
	background: #f1f1f1; /* 트랙의 배경 색상 */
}

/* 스크롤바 핸들 (핸들은 움직이는 부분) */
.fileListContainer::-webkit-scrollbar-thumb {
	background: #d9d9d9; /* 핸들의 배경 색상 */
	border-radius: 10px; /* 핸들의 모서리를 둥글게 */
}
/* 스크롤바 전체 스타일 */
.past::-webkit-scrollbar {
	width: 10px; /* 스크롤바의 너비 */
	height: 5px; /* 수평 스크롤바의 높이, 수직 스크롤 시 필요 */
}

/* 스크롤바 트랙 (트랙은 스크롤바 배경) */
.past::-webkit-scrollbar-track {
	background: #f1f1f1; /* 트랙의 배경 색상 */
}

/* 스크롤바 핸들 (핸들은 움직이는 부분) */
.past::-webkit-scrollbar-thumb {
	background: #d9d9d9; /* 핸들의 배경 색상 */
	border-radius: 10px; /* 핸들의 모서리를 둥글게 */
}
.fileItem {
	display: flex;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #e1e4e8; /* 하단 테두리 설정 */
	background-color: #fafafa; /* 배경색 */
}

.fileItem:last-child {
	border-bottom: none; /* 마지막 항목의 테두리 제거 */
}

.fileItem input[type="checkbox"] {
	margin-right: 10px; /* 체크박스와 파일 이름 사이 간격 */
	cursor: pointer; /* 커서 모양 변경 */
}

.fileItem span {
	flex-grow: 1;
	color: #585858;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.backColorDif {
	border: 1px solid #f3f3f3;
	background: #f3f3f3;
}

.sign {
	padding: 30px 0 30px 0;
	font-size: 30px;
	letter-spacing: 1px;
	//font-style: italic;
}

@media print {
	.postall {
		page-break-inside: avoid; /* 페이지 나눔 방지 */
		width: 100%;
		max-width: 100%;
		box-sizing: border-box;
		padding: 5mm; /* 적절한 패딩 설정 */
	}
	body {
		margin: 0;
		padding: 0;
		font-size: 10pt; /* 폰트 크기 조절 */
	}
	.rmflavks {
		width: 50%;
	}
	.postInputSet {
		justify-content: center;
		padding: 0;
	}
}

.printBTN {
	position: fixed;
	bottom: 5%;
	left: 20%;
	width: 100px;
	padding: 8px 33px 8px 32px;
	text-align: center;
	border-radius: 30px;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	background-color: #4caf50;
	color: #fff;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
}
.saveBTN {
	position: fixed;
	bottom: 5%;
	right: 6%;
	width: 100px;
	padding: 8px 33px 8px 32px;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	background: #104168;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
	color: #fff;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	z-index: 10;
}

.delBTN {
	position: fixed;
	bottom: 5%;
	right: 12%;
	width: 100px;
	padding: 6px 23px 6px 23px;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	border: 2px solid #f17070;
	background: #fff;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
	color: #f17070;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	z-index: 10;
}
