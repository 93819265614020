li {
	color: inherit; // 기본 색상을 상속받도록 설정
}

.modalBackground {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	width: 100vw;
	height: 100vh;
}

.modalContainer {
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	max-width: 500px;
	width: 100%;
}

.modalHeader h2 {
	margin: 0;
	color: #333;
}

.modalContent {
	margin-top: 20px;
	height: 50vh;
	overflow-y: scroll;
	ul ul {
		margin-bottom: 20px;
		li {
			line-height: 2;
			&:hover {
				background-color: #d9d9d9;
			}
			&:first-of-type {
				margin-top: 5px;
			}
		}
	}
}
.modalFooter {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}

.modalFooter button {
	background-color: #007bff;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
}

// 알림 모달 스타일

.notificationModal {
	background: #fff;
	border-radius: 10px;
	width: 700px;
	max-width: 90%;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	animation: fadeIn 0.3s ease-in-out;
  
	.modalContent {
	  padding: 20px;
	  text-align: center;
	  position: relative;
  
	  .close {
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 1.5rem;
		cursor: pointer;
		color: #999;
		transition: color 0.3s;
  
		&:hover {
		  color: #555;
		}
	  }
  
	  h2 {
		margin-bottom: 20px;
		font-size: 1.5rem;
		color: #333;
	  }
  
	  ul {
		list-style: none;
		padding: 0;
  
		li {
		  margin-bottom: 15px;
		  background: #f9f9f9;
		  padding: 15px;
		  border-radius: 8px;
		  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  transition: background 0.3s;
  
		  &:hover {
			background: #e9ecef;
		  }
  
		  .notificationDetails {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
  
			p {
			  margin: 5px 0;
			  font-size: 0.9rem;
			  color: #666;
			}
		  }
  
		  button {
			padding: 8px 16px;
			border: none;
			border-radius: 5px;
			background: #28a745;
			color: #fff;
			font-size: 0.9rem;
			cursor: pointer;
			transition: background 0.3s;
  
			&:hover {
			  background: #218838;
			}
		  }
		}
	  }
	}
  
	@keyframes fadeIn {
	  from {
		opacity: 0;
		transform: translateY(-10px);
	  }
	  to {
		opacity: 1;
		transform: translateY(0);
	  }
	}
  }
  


  /* 오버레이 스타일 */
.writerModal {
	position: fixed;
	top: 80px;
	right: 2%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	animation: fadeIn 0.5s;
  }
  
  @keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* 페이드 아웃 애니메이션 */
@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* 페이드 아웃 클래스 */
.writerModal.fade-out {
	animation: fadeOut 0.5s;
}
  /* 모달 창 스타일 */
  .writerModal > div {
	background: white;
	padding: 10px;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	width: 100px;
	text-align: center;
  }
  
  /* 제목 스타일 */
  .writerModal h2 {
	margin-bottom: 20px;
  }
  
  /* 사용자 목록 스타일 */
  .writerModal ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
  }
  
  .writerModal ul li {
	margin: 10px 0;
	padding: 10px;
	cursor: pointer;
	background: #f5f5f5;
	border-radius: 5px;
	transition: background 0.3s;
  }
  
  .writerModal ul li:hover {
	background: #e0e0e0;
  }
  
  /* 닫기 버튼 스타일 */
  .writerModal button {
	margin-top: 20px;
	padding: 10px 20px;
	background: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background 0.3s;
  }
  
  .writerModal button:hover {
	background: #0056b3;
  }
  