.chat-modal {
	background: white;
	border-radius: 16px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	width: 100%;
	max-width: 400px;
	position: fixed;
	top: 30%; /* 하단에 고정 */
	right: 20px;
	z-index: 1000;
	height: 470px;

	.chat-header {
		background: #f3f4f6;
		padding: 10px 20px;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			margin: 0;
			padding: 8px;
			font-size: 1rem;
			color: #333;
		}

		button {
			border: none;
			background: none;
			cursor: pointer;
			color: #888;
			font-size: 1rem;
		}
	}

	.messages-list {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow-y: auto;
		max-height: 358px;

		li {
			padding: 8px 20px;
			border-bottom: 1px solid #f0f0f0;

			span {
				font-size: 0.8rem;
				color: #999;
			}

			p {
				margin: 5px 0;
				font-size: 0.9rem;
			}
		}
	}

	.chat-input {
		padding: 10px 20px;
		display: flex;
		border-top: 1px solid #f0f0f0;
		width: 100%;
		position: absolute;
		bottom: 0;
		input {
			flex: 1;
			padding: 10px;
			border: 1px solid #ddd;
			border-radius: 8px;
			margin-right: 10px;
		}

		button {
			padding: 10px 20px;
			background-color: #5cb85c;
			color: white;
			border: none;
			border-radius: 8px;
			cursor: pointer;
		}
	}
}
