.joinCompanyInfo{
    width:380px;
    h3{
        font-size:28px;
        text-align: center;
        padding-bottom:10px;
    }
    h4{
        font-size:13px;
        text-align: center;
        padding-bottom:20px;
        span{
            color:$main-green; 
            margin-left:5px;
        }
    }
    .findAddress{
        .daumAddress{
           border:1px solid red;
        }
        .inputFlex{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            button.findAddressBtn{
                width:35%;
                height:50px;
                border-radius: 10px;
                border:1px solid $main-green;
                background-color:$white;
                color:$main-green;
            }
            input{
                height:50px;
                width:100%;
                border-radius: 10px;
                border:1px solid #DBDBDB;
                &:not(:last-of-type){
                    margin-bottom:10px;
                    background-color: #EDEDED;
                    color:#909090;
                }
                &.postCode{
                    width:64%;
                }
                &.findAddressBtn{
                    width:35%;
                    height:50px;
                    border-radius: 10px;
                    border:1px solid $main-green;
                    background-color:$white;
                    color:$main-green;
                    cursor: pointer;
                   
                }
            }
            
        }
        .modal{
            border:1px solid red;
        }
    }
    
    button.closeBtn{
        font-size:20px;
        background:none;
        font-weight:bold;
    }
    button.startBtn{
        width:100%;
        height:50px;
        color:$white;
        background-color: $main-navy;   
        font-size:18px;
        font-weight:700;
        border-radius: 10px;
    }
    
    .userGuide{
        margin-top:30px; 
        h5{
            color:#E10303;
            padding-bottom:10px;
            font-weight:900;
            font-size:13px;
            img{
                transform: translateY(15%);
                margin-right:3px;
            }
        }
        ul{
            li{
                font-size:13px;
                line-height:22px;
                color:#4F4F4F;
                font-weight:bold;
            }
        }
    }

}

