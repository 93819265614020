.loginContainer{
    width:380px;
    height:auto;
    display:flex;
    justify-content: center;
    flex-direction: column;
    h2{
        color:$main-green;
        text-align:center;
        font-size:32px;
        margin: 0px 0px 80px;
        span{
            color:$main-navy;
        }
    }
    h3{
        color:#343434;
        font-size:20px;
        text-align:center;
    }
    label{
        color:#646464;
        font-size: 13px;
        line-height: 22px;
        font-family: 'Pretendard-Regular';
        span{
            color:$main-green;
            margin-left:5px;
            font-family: 'Pretendard-Regular';
        }
    }
    input{
        color:#6f6f6f;
        font-size:13px;
        width: 100%;
        height: 50px;
        outline: none;
        border: 1px solid #e2e2e2;
        border-radius: 12px;
        padding: 0px 8px;
        font-family: 'Pretendard-Regular';
    }
    .InputBtw {
        margin: 20px 0px 5px;
        display: flex;
        justify-content: space-between;
        align-items: end;
        a {
            color: #104168;
            font-size:13px;
            height: 22px;
            text-Decoration: none;
        }
    }

    .keepingLogin {
        color:#6f6f6f;
        font-size:13px;
        width:15px;
        height:15px;
        outline: none;
        margin: 12px 5px;
    }
    .keepingLoginLabel {
        margin: 0px;
    }
    .alignItem {
        display: flex;
        align-items: center;
        margin: 10px 0px;
    }
    .flexRow {
        justify-content: center;
        a {
            color: #104168;
            margin-left: 10px;
            line-height: 55px;
        }
        p {
            line-height: 54px;
        }
    }
}

// 회원가입 첫 페이지
.joinUserInfoForm {
    width:380px; height:auto;
    display:flex;
    justify-content: center;
    flex-direction: column;
    // 회원가입 모달
    .joinPhoneSelect {
        margin: 0px;
        padding: 0px;
        width: 173px;
        height: 103px;
        border-radius: 5px;
        border: 1px solid #F2F2F2;
        background: #FFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
        display: grid;
        grid-template-columns: 40% 60%;
        color: #656565;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.52px;
        li {
            margin: 0px 0px 0px 18px;
        }
        li:hover {
            cursor: pointer;
            font-weight: 700;
        }
    }
        
    // 회원가입 이미지 앱솔
    .joinImgs {
        position: absolute;
        width: 12px;
        height: 12px;
        left: 17px;
        top: 13px;
    }
    // 회원가입 이미지 relative
    .joinRelative {
        position: relative;
    }

    .joinUserInputBox {
        font-family: 'Pretendard-Regular';
        width: 100%;
        height: 37px;
        line-height: 37px;
        outline: none;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        padding: 0px 0px 0px 39px;
        background-color: white;
        display: flex;
        align-items: center;
        input {
            width: 98%;
            padding: 1px 0px 0px 10px;
            border: 1px solid #e2e2e2;
            border-left: none;
            border-right: none;
            background-color: none;
            outline: none;
        }
        div {
            width: 400px;
            margin-left: -23px;
        }
    }
    .userLoginInputBox {
        position: relative;
        margin-top: 8px;
        input {
            font-size: 13px;
            height: 37px;
            font-weight: 500;
            color: #343434;
        }
        .inputStyle1 {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-bottom: none;
        }
        .inputStyle2 {
            border-radius: 0px;
            border-bottom: none;
        }
        .inputStyle3 {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
        .joinInfoBtnSet {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-column-gap: 7px;
            button {
                width: 82px;
                height: 25px;
                border-radius: 5px;
                margin: 0px 3px;
                background: white;
                color: gray;
                border: 1px solid #e2e2e2;
                font-family: 'Pretendard-Regular';
                &.selected {
                  border: 1px solid #5DB751;
                  color: #5DB751;
                  background: var(--5, #F7FFF5);
                }
            }
        }
        .phoneCompanyButton {
            color: gray;
            text-align: start;
        }
    }
    .loginModatPosition {
        position: absolute;
        left: 53%;
        top: 62%;
        z-index: 1;
    }
    
    .agreementConsentCertification {
        justify-content: space-between;
        width: 100%;
        outline: none;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        padding: 0px 8px;
        background-color: white;
        margin: 10px 0px 25px;
        cursor: pointer;
        user-select: none;
        p {
            margin: 0px;
            color: #343434;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;    
            height: 37px;
            line-height: 37px;
            font-family: 'Pretendard-Regular';
        }
        .necessaryP {
            color: #5DB751;
            padding-left: 31px;
        }
        .loginImgSet {
            margin-left: -9px;
        }
    }
    // 필수 동의 열렸을 때
    .joinOpenAgreement {
        justify-content: space-between;
        width: 100%;
        outline: none;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        padding: 0px 8px;
        background-color: white;
        margin: 10px 0px 0px 0px;
        cursor: pointer;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        p {
            margin: 0px;
            color: #343434;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;    
            height: 37px;
            line-height: 37px;
            border-bottom: none;
        }
        .necessaryP {
            color: #5DB751;
            padding-left: 31px;
        }
        .loginImgSet {
            margin-left: -9px;
        }
    }
    // 동의 모달 css
    .agreementBox {
        user-select: none;
        width: 100%;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        padding: 0px 8px;
        background-color: white;
        border-top: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        .agreeMentMain {
            align-items: center;
            justify-content: space-between;
            color: #666;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            p {
                justify-content: space-between;
                color: #666;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
                cursor: pointer;
                margin: 7px 0px;
            }
            .customCheckbox {
                display: none; // 기본 체크박스 숨김
            }
    
            .customCheckboxLabel {
                position: relative;
                padding-left: 25px; // 체크박스와 텍스트 간격
                cursor: pointer;
    
                .checkboxCustom {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 17px;
                    width: 17px;
                    background-color: #eee; // 체크박스 기본 배경색
                    border-radius: 4px; // 체크박스 모서리 둥글게
    
                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                }
    
                input:checked + .checkboxCustom {
                    background-color: #104168; // 체크됐을 때의 배경색 변경
    
                    &:after {
                        display: block;
                        left: 6px;
                        top: 2px;
                        width: 4px;
                        height: 8px;
                        border: solid white;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
    
    .joinImgSize {
        width: 12px;
        height: 12px;
        margin-top: 13px;
    }
    
    .loginPrompt {
        width: 100%;
        padding-top: 20px;
        color: #343434;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-top: 1px solid #e2e2e2;
        text-align: center;
        margin-bottom: 33px;
        a {
            color: #104168;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
        }
    }
}





