.mainHeader {
	width: 100%;
	box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
	h1 {
		width: 116px;
		cursor: pointer;
		img {
			width: 100%;
		}
	}
	> div {
		width: 1200px;
		height: 80px;
		line-height: 80px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 auto;
		nav {
			ul {
				display: flex;
				justify-content: space-around;
				li {
					padding: 0 10px;
					font-size: 15px;
					color: #373737;
					text-align: center;
					font-weight: 700;
					cursor: pointer;
				}
			}
		}
		.loginJoin {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			button {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 5px;
				border: 1px solid #e0e0e0;
				font-weight: 700;
				font-size: 15px;
				cursor: pointer;
				a {
					line-height: 30px;
					padding: 0 10px;
				}
				&.join {
					a {
						color: #828282;
					}
					background: $white;
				}
				&.login {
					border: none;
					background-color: $main-navy;
					a {
						color: $white;
					}
				}
			}
		}
	}
}
.mainFooter {
	width: 100%;
	padding: 40px 0;
	background-color: #343434;
	h1 {
		width: 112px;
		cursor: pointer;
		img {
			width: 100%;
		}
	}
	.footerContents {
		width: 1200px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 auto;
		.funnelsInfo {
			width: 40%;
			h1 {
				margin-bottom: 30px;
				cursor: pointer;
			}
			.companyInfo,
			.companyContact {
				p {
					color: #868686;
				}
				font-weight: 500;
				line-height: 25px;
				margin-bottom: 30px;
			}
			.copyRight {
				color: #aeaeae;
			}
		}
		nav {
			width: 55%;
			position: relative;
			&::after {
				content: "";
				width: 100%;
				border: 1px solid #5b5b5b;
				position: absolute;
				top: 32.5px;
				left: 0;
			}
			ul.fnb {
				display: flex;
				justify-content: space-between;
				> li {
					color: $white;
					cursor: pointer;
					font-weight: 700;
					font-size: 16px;
					line-height: 30px;
					ul {
						display: flex;
						flex-wrap: wrap;
						flex-direction: column;
						margin-top: 10px;
						li {
							color: #aeaeae;
							font-size: 14px;
							cursor: pointer;
							font-weight: 500;
							line-height: 20px;
						}
					}
				}
			}
		}
	}
}
