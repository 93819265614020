$black: #222222;
$white: #fafafa;
$sidebarwith: 200px;

$main-navy: #104168;
$main-green: #5db751;
$main-green-bright: #55f040;
$main-gradation: linear-gradient(90deg, #5db751 0%, #01688d 100%);

// $processStep1_dark: #fbc04d;
// $processStep1_middle: #fff3d7;
// $processStep1_bright: #fdfaf3;
// $processStep2_dark: #d45a52;
// $processStep2_middle: #ffe9e5;
// $processStep2_bright: #fdf6f5;
// $processStep3_dark: #3da3b1;
// $processStep3_middle: #e1f4f6;
// $processStep3_bright: #f3fafb;
// $processStep4_dark: #7b5c93;
// $processStep4_middle: #f8ebfe;
// $processStep4_bright: #fbf5fe;

$processStep-colors: (
	1: (
		dark: #fbc04d,
		middle: #fff3d7,
		bright: #fdfaf3,
	),
	2: (
		dark: #d45a52,
		middle: #ffe9e5,
		bright: #fdf6f5,
	),
	3: (
		dark: #3da3b1,
		middle: #e1f4f6,
		bright: #f3fafb,
	),
	4: (
		dark: #7b5c93,
		middle: #f8ebfe,
		bright: #fbf5fe,
	),
);

@function get-process-color($index, $brightness) {
	@return map-get(map-get($processStep-colors, $index), $brightness);
}

// map-get(map-get($processStep-colors, 1), dark); // #fbc04d

$sidebarwith: 200px;
$headerHeight: 60px;
