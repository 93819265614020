.mainContents{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        font-size: 24px;
        font-weight:700;
        color:$main-green;
        padding-bottom:20px;
        span{
            color:$main-navy;
        }
    }
    img{
        width:509px;
        margin-bottom:50px;
    }
    button{
        display: inline-block;
        width: 380px;
        height: 86px;
        border-radius: 50px;
        font-size: 50px;
        font-weight: 700;
        color:$white;
        background: var(--3, linear-gradient(95deg, #5DB751 16.14%, #00688D 75.19%));
    }
}