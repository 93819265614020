//$sidbarwith: 200px;
//@import "./common";
.homeNavSection {
	z-index: 10;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: fixed;
	height: $headerHeight;
	top: 0;
	width: 100%;
	padding: 0 2%;
	left: 50%;
	transform: translateX(-50%);
	background: $white;
	white-space: nowrap;
	box-shadow: 0px 3px 5px 0px rgba(152, 152, 152, 0.1);

	transition-duration: 0.5s;
	.downImg {
		width: 24px;
		height: 24px;
	}
	.memberImg {
		width: 16px;
		height: 16px;
	}
	.flexRow {
		align-items: center;
		li {
			img {
				margin: 0px 15px;
				cursor: pointer;
			}
		}
		.DTlogoSize {
			//width: 36px;
			//margin: 20px 43px 0 23px;
			margin: 20px 15px 0 0px;
		}
		.profile {
			width: 40px;
			height: 40px;
			margin: 0px 10px 0 35px;
			background-color: #ffb72b;
			border-radius: 50%;
			color: $white;
			text-align: center;
			line-height: 40px;
			cursor: pointer;
		}
		.profileImg {
			width: 40px;
			height: 40px;
			margin: 0px 10px 0 35px;
			background-color: $black;
			border-radius: 50%;
		}
		.homeNavUserName {
			color: #343434;
			font-family: "Pretendard";
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-right: 20px;
			cursor: pointer;
		}
		.homeNavMember {
			color: #7c7c7c;
			font-family: "Pretendard";
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-left: -10px;
			margin-bottom: 1px;
		}
		.homeNavHostName {
			color: #343434;
			font-family: "Pretendard";
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-right: -5px;
		}
	}
}

.homeSidebarSection {
	width: $sidebarwith;
	background: #4a5b69;
	height: 100%;
	transition-duration: 0.5s;
	position: fixed;
	box-shadow: 3px 0px 5px 0px rgba(152, 152, 152, 0.1);
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	left: 0%;

	> ul {
		width: 100%;
		padding-top: 100px;
		> li {
			font-size: 18px;
			font-weight: normal;
			text-align: center;

			width: 100%;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			cursor: pointer;

			&:not(:first-of-type) {
				border-top: 1px solid #5d6f7d;
			}
			h4 {
				padding: 20px 0;
				font-weight: normal;
				color: $white;
				width: 100%;
				height: 100%;
			}
			&.addBtn {
				padding: 20px;
				span {
					background-color: $white;
					width: 20px;
					height: 20px;
					border-radius: 100%;
					line-height: 18px;
					font-size: 20px;
					color: #4a5b69;
				}
			}
			&.chooseDental {
				h4 {
					color: $main-green-bright;
				}
			}
			ul {
				width: 100%;
				li {
					width: 100%;
					background-color: #394753;
					color: $white;
					padding: 20px;
					font-size: 15px;
				}
			}
		}
	}

	.sidebarToggle {
		width: 26px;
		height: 40px;
		border-radius: 0 10px 10px 0;

		font-size: 13px;
		line-height: 40px;
		padding-left: 4px;

		color: $white;
		background-color: $main-green;

		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(100%, -50%);

		cursor: pointer;
	}
	&.sidebarClose {
		left: -$sidebarwith;
	}
}

// 벨 우측 상단
.homeNavSection ul li {
	position: relative;
}

.homeNavSection ul li .notification-count {
	width: 15px;
    height: 15px;
    left: 28px;
    position: absolute;
    top: -5px;
    text-align: center;
    line-height: 15px;
    border-radius: 50%;
    background-color: red;
    color: white;
	font-size: 10px;
    font-weight: bold;
}
