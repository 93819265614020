.loginFind{
    width:380px;
    h2{
        font-size:28px;
        line-height:38px;
        color:#343434;
        text-align:center;
        font-weight:bold;
        letter-spacing: -0.75px;
        padding-bottom:40px;
    }
    label{
        font-size:13px;
        font-weight:bold;
        span{
            color:$main-green;
            margin-left:5px;
        }
    }
    input{
       margin-top:10px;
        &.inputBackColorBtn{
            background-color:#EDEDED;
        }
        &[type=text],&[type=number]{
            color:#6f6f6f;
            font-size:13px;
            width:64%;
            height: 50px;
            outline: none;
            border: 1px solid #e2e2e2;
            border-radius: 12px;
            padding: 0px 8px;
        }
        &[type=button]{
            width:35%;
            height:50px;
            border-radius: 10px;
            background-color:$white;
            cursor: pointer;
            
            &.codeSubmitBtn{
                border: 1px solid #e2e2e2;
                color:#6f6f6f;
            }
            &.codeCheckBtn{
                border:1px solid $main-green;
                color:$main-green;
            }
        }
    }
    .flex{
        display: flex;
        justify-content:space-between ;

    }
    .FormBTN{
        margin-top:50px;
    }
}
.loginPassFindModal{
    width: 380px;
    height: 150px;
    background-color: $white;
    .modalContent {
        width: 100%;
        height: 100%;
        padding: 20px;
        position: relative;
    .closeBtn{
        font-size: 18px;
        background: none;
        width: 80px;
        height: 40px;
        border-radius: 30px;
        position: absolute;
        right: 0;
        bottom: 0;
        color: $white;
        background-color: #104168;
        border: none;
        cursor: pointer;
    }
}


    .modalContent{
        border:1px solid red;
    }
    
    
}    

