.HomeContents {
	margin-top: $headerHeight;
	transition-duration: 0.5s;
	width: 100%;
	height: calc(100vh - $headerHeight);
	padding: 0 30px 30px 30px;
	background-color: #f8f8f8;
	&.sidebarOpen {
		width: calc(100% - $sidebarwith);
		margin-left: $sidebarwith;
	}
	.borderBox {
		border: 1px solid #d9d9d9;
		border-radius: 10px;
		background-color: #fafafa;
	}
	.dentalLabSelect {
		width: 500px;
		div {
			cursor: pointer;
		}
	}
	.dentalLabNameWrap {
		display: flex;
		align-items: center;
		padding: 45px 0 27px 0;
		gap: 0 12px;
		h3.dentalLabName {
			font-size: 23px;
			font-weight: 700;
		}
		.processEditBtn {
			width: 32px;
			height: 32px;
			border-radius: 4px;
			border: 1px solid #eaeaea;
			background-color: $white;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			position: relative;
		}
	}
	.dentalCurrentState {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		overflow-x: auto;
		position: relative;
		margin-bottom: 20px;
		background-color: $white;
		height: 320px;
		padding: 18px 24px;
		h3 {
			font-size: 18px;
		}
		> div {
			display: inline-flex;
			justify-content: space-between;
			gap: 0 10px;
			flex-wrap: wrap;
			.dateBox {
				color: #8b8b8b;
				input[type="date"] {
					border: 1px solid #d8d8d8;
					font-size: 13px;
					padding: 6px;
					color: #8b8b8b;
					background-color: transparent;
				}
				span {
					margin: 0 8px;
					color: #8b8b8b;
				}
			}
		}

		ul.dentalStateList {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			gap: 0 5%;
			border-top: 2px solid #efefef;
			margin-top: 15px;
			padding-top: 20px;
			> li {
				cursor: pointer;
				//background-color: red;
				border-radius: 10px;
				padding: 18px 30px;
				@for $i from 1 through 4 {
					&:nth-of-type(#{$i}) {
						cursor: pointer;
						border-radius: 10px;
						padding: 18px 30px;
						background-color: get-process-color(
							$i,
							bright
						); // true를 통해 밝은 색상을 얻습니다.
						//background-color: gold;
						.count {
							color: get-process-color($i, dark);
						}
					}
				}
				h4 {
					font-size: 15px;
					margin-bottom: 15px;
				}
				ul {
					display: flex;
					justify-content: flex-start;
					gap: 0 20px;
					li {
						display: flex;
						align-items: center;
						flex-direction: column;
						min-width: 100px;
						position: relative;
						.imgBox {
							width: 65px;
							height: 65px;
							border-radius: 100%;
							background-color: $white;
							display: inline-flex;
							justify-content: center;
							align-items: center;
						}

						h5 {
							font-size: 12px;
							padding: 8px 0;
						}
						p {
							font-size: 9px;
							display: flex;
							align-items: center;
							span.count {
								font-size: 22px;
								font-weight: 600;
								margin-right: 2px;
							}
						}
						button {
							width: 12px;
							height: 12px;
							border-radius: 100%;
							border: 0.5px solid #bbbbbb;
							color: #bbbbbb;
							text-align: center;
							line-height: 10px;
							padding-bottom: 2px;
							background-color: $white;
							position: absolute;
							right: 0;
							top: 0;
						}
					}
				}
			}
		}
	}
	.patientsDataBoxWrap {
		overflow-x: hidden;
		max-height: 500px;
		height: 45vh;
		width: 100%;
		&.sidebaropen {
			width: calc(100% - $sidebarwith);
		}
	}
	.patientsDataBox {
		position: relative;
		height: auto;

		.nav {
			position: sticky;
			width: 100%;
			height: 60px;
			top: 0;
			left: 0;
			margin-bottom: 27px;
			border-bottom: 1px solid #d9d9d9;
			display: flex;
			justify-content: space-between;
			align-items: center;
			z-index: 5;
			background-color: $white;
			> ul {
				width: 100%;
				height: 100%;
				li {
					width: 170px;
					height: 100%;
					font-size: 12px;
					cursor: pointer;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					color: #747474;
					font-weight: 700;
					float: left;
					&:hover {
						background: #edf7ff;
						color: #104168;
					}
				}
				.navSelect {
					background: #edf7ff;
					color: #104168;
				}
			}
			> div {
				font-size: 12px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 0 20px;
				width: 100%;
				height: 100%;
				.sortBtn {
					display: flex;
					justify-content: space-between;

					button {
						background-color: #ffffff;
						width: 75px;
						height: 25px;
						font-size: 11px;
						color: #afb9c1;
						border: 1px solid #efefef;
						&:nth-of-type(odd) {
							border-radius: 4px 0 0 4px;
						}
						&:nth-of-type(even) {
							border-radius: 0 4px 4px 0;
							margin-right: 10px;
						}
					}
					.chooseBtn {
						color: white;
						background-color: #104168;
						border: 1px solid #104168;
					}
				}

				.dataSearch {
					width: 235px;
					height: 25px;
					padding: 0 5px;
					font-size: 13px;
					color: #b9b9b9;
					border-bottom: 1px solid #d8d8d8;
					display: flex;
					justify-content: space-between;
					align-items: center;
					input {
						width: calc(100% - 15px);
						height: 100%;
						border: none;
						background-color: transparent;
						&:focus {
							outline: none;
							border: none;
						}
						margin-left: 7px;
					}
					button {
						color: #d8d8d8;
						background: none;
						font-weight: 100;
					}
				}
			}
		}
		ul.processLabel {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-start;
			position: absolute;
			> li {
				@for $i from 1 through 4 {
					&:nth-of-type(#{$i}) p {
						background-color: get-process-color(
							$i,
							dark
						); // true를 통해 밝은 색상을 얻습니다.
						font-size: 15px;
						color: $white;
					}
					&:nth-of-type(#{$i}) li {
						span {
							background-color: get-process-color($i, middle);
						}
						background-color: get-process-color($i, bright);
						font-size: 14px;
						font-weight: 600;
						color: #343434;
					}
				}
				p,
				span {
					display: block;
					padding: 7px 0px 7px 15px;
				}
				ul.processOption {
					display: flex;
					justify-content: space-between;
					height: 100%;
				}
			}
		}
		ul.tableFormHead {
			width: calc(100% - 20px);
			margin: 0 10px;
			display: flex;
			justify-content: space-between;
			border-top: 1px solid;
			border-bottom: 1px solid;
			border-color: #ececec;
			padding: 12px 0;
			li {
				font-size: 15px;
				font-weight: 600;
				width: 12.5%;
			}
		}
		.patientDatas {
			width: 100%;
			.patientData {
				background-color: $white;
				&:hover {
					background-color: #cccccc;
					cursor: pointer;
				}
				p {
					color: #343434;
					font-size: 12px;
					font-weight: 500;
					line-height: 15px;
				}
			}

			&.bordForm {
				margin-top: 90px;
				.patientData {
					user-select: none;
					overflow: hidden;
					border: 1px solid #a3a3a3;
					border-radius: 5px;
					padding: 10px;
					p {
						&:not(:first-of-type) {
							margin-top: 5px;
						}
						span {
							margin-left: 5px;
						}
						&.patientName {
							font-size: 13px;
							font-weight: 600;
						}
						&.chartNumber {
							color: #7c7c7c;
							font-size: 12px;
						}
						&.patientState {
							color: #7c7c7c;
						}
					}
					.redPoint {
						position: absolute;
						right: 8px;
						width: 10px;
						height: 10px;
						background-color: lightcoral;
						border-radius: 50%;
					  }
					  
				}
			}
			&.tableForm {
				.patientData {
					font-size: 12px;
					border-radius: 5px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;

					&:hover {
						box-shadow: 0px 0px 3px #868686;
					}
					p {
						width: 12.5%;
						&.patientState {
							display: flex;
							justify-content: center;
							span {
								width: 80px;
								height: 20px;
								text-align: center;
								line-height: 20px;
								border-radius: 4px;
								background-color: gold;
								display: block;
								font-size: 12px;
								color: $white;
							}
						}
					}
				}
			}
		}
		.writeMoveBtn {
			position: fixed;
			right: 2%;
			bottom: 2%;
			width: 185px;
			display: flex;
			flex-wrap: wrap;
			gap: 10px 0;
			z-index: 10;
			button {
				width: 100%;
				height: 55px;
				border-radius: 30px;
				font-size: 18px;
				font-weight: 700;

				&.newWrite {
					background-color: $white;
					border: 2px solid $main-green;
					color: $main-green;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					gap: 0 10px;
					&:hover {
						box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25) inset;
						//0px 2px 8px 0px rgba(0, 0, 0, 0.25);
					}
				}
				&.moveChart {
					background-color: $main-green;
					padding-left: 30px;
					span {
						//left: 1%;
						transform: translate(calc(-185px / 2 + 50px / 2 + 3px), -50%);
					}
				}
				&.saveChart {
					//background-color: #d9d9d9;
					background-color: #f53131;
					padding-right: 30px;
					span {
						transform: translate(calc(185px / 2 - 50px / 2 - 3px), -50%);
					}
				}
				&.moveChart,
				&.saveChart {
					color: $white;
					box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25) inset;
					//0px 2px 8px 0px rgba(0, 0, 0, 0.25);
					position: relative;
					transition-duration: 0.3s;
					span {
						width: 50px;
						height: 50px;
						background-color: $white;
						border-radius: 100%;
						position: absolute;
						top: 50%;
						left: calc(50% - 25px);
						//transform: translateY(-50%);
						transition-duration: 0.3s;
					}
				}
			}
		}
	}

	.fullScreen,
	.fullscreen-enabled {
		background-color: white;
		position: relative;
		overflow-y: scroll;
		overflow-x: hidden;

		.processLabel {
			width: 100%;
		}
	}

	button.fullScreenHandle {
		font-size: 14px;
		color: $white;
		background: $main-gradation;
		padding: 7px 24px;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		position: absolute;
		top: 0%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
		display: inline-flex;
		align-items: flex-start;
		gap: 4px;
	}

	.fc {
		.fc-daygrid-day-events {
			cursor: pointer;
		}
		.fc-header-toolbar {
			/* 년도, 날짜 */
			.fc-toolbar-title {
				font-size: 1.5em;
			}
		}
		.fc-day {
			font-size: 0.8em;
			font-weight: 700;
		}

		/* 일요일 날짜: 빨간색 */
		.fc-day-sun a {
			color: rgb(143, 143, 143);
		}
		.fc-icon {
			color: $white;
		}

		/**/
		.fc-event-title {
			padding: 5px;
			line-height: 1.5em;
			white-space: pre-line; //공백있으면 줄바꿈
		}
	}
}
